
import { io } from "socket.io-client";
import { defineComponent, computed, ref, onMounted } from "vue";
import { useStore } from "vuex";
import ApiService from "@/core/services/ApiService";
import moment from "moment";
import Swal from "sweetalert2/dist/sweetalert2.js";

interface INotif {
  _id: string;
  created_at: string;
  is_read: number;
  stderr: string;
  stdout: string;
}

export default defineComponent({
  name: "kt-notif-menu",
  emit: ["notif-change", "notif-on"],
  components: {},
  setup(props, { emit }) {
    const notification_data = ref<Array<INotif>>([]);
    const store = useStore();
    const user = computed(() => store.getters.currentUser.data);
    const socket = io("https://websocket-kurasi.onlinemonitoring.id/", {
      query: { username: user.value.username },
    });
    const unread_count = ref(0);
    const notif_count = ref(0);
    const limit_item = ref(10);
    const page = ref(1);
    const page_info = ref({
      limit: 10,
      page: 1,
      total: 0,
      _id: null,
    });
    const is_loading = ref(true);
    const is_loadmore = ref(false);
    const writeContent = (text: string) => {
      var new_text = text;
      new_text = new_text.replace(/\n/g, "<br />");
      return new_text;
    };

    function getNotification(load_more = false) {
      if (load_more) {
        is_loadmore.value = true;
      } else {
        is_loading.value = true;
      }
      const params = {
        id_user: store.getters.currentUser.data._id,
        page: page.value,
        limit: limit_item.value,
      };
      const query = Object.keys(params)
        .map((key) => `${key}=${params[key]}`)
        .join("&");
      const url = `https://mona-api.onlinemonitoring.id/notif-message-siputra?${query}`;

      ApiService.get(url)
        .then((res) => {
          if (!load_more) {
            notification_data.value = res.data.tasks;
            page_info.value = res.data.page_info[0]
              ? res.data.page_info[0]
              : [];
            notif_count.value = page_info.value.total;
            unread_count.value = res.data.count_unread;
            emit("notif-change", unread_count.value);
          } else {
            res.data.tasks.forEach((el: any) => {
              notification_data.value.push(el);
            });
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          is_loading.value = false;
          is_loadmore.value = false;
        });
    }
    function readNotification(data: any, index: number) {
      updateNotification([data._id], index);
      if (data.is_read == 0) {
        emit("notif-change", (unread_count.value -= 1));
      }
      Swal.fire({
        title: "Detail Crawler",
        html: `${writeContent(data.stdout)}`,
        icon: "info",
        confirmButtonText: "Ok",
        buttonsStyling: false,
        width: "600px",
        customClass: {
          confirmButton: "btn btn-light-primary",
        },
      });
    }
    function updateNotification(id: any, index: number) {
      const url = `https://mona-api.onlinemonitoring.id/notif-message-siputra/update`;
      ApiService.post(url, {
        data: {
          ids: id,
        },
      }).then(() => {
        notification_data.value[index].is_read = 1;
      });
    }
    function listenNotification() {
      socket.on("notif-siputra", (res) => {
        emit("notif-on");
        const data = {
          _id: res.notif_id,
          created_at: res.created_at,
          is_read: 0,
          stderr: res.stderr,
          stdout: res.stdout,
        };
        notification_data.value.unshift(data);
        notif_count.value += 1;
        unread_count.value += 1;
        emit("notif-change", unread_count.value);
      });
    }
    function loadMore() {
      page.value += 1;
      getNotification(true);
    }

    onMounted(() => {
      getNotification();
      listenNotification();
    });
    return {
      notification_data,
      unread_count,
      notif_count,
      page_info,
      is_loading,
      is_loadmore,
      moment,
      readNotification,
      updateNotification,
      loadMore,
      writeContent,
    };
  },
});
